import { defineStore } from 'pinia'

interface State {
  showMobileSidebar: boolean
  domains: DomainInit[]
  httpError: number | null
  firebaseToken: string | null
}

export const useGeneralStore = defineStore('General', {
  state: (): State => ({
    showMobileSidebar: false,
    domains: [],
    httpError: null,
    firebaseToken: null,
  }),
  getters: {
    findDomain: (state) => (id: number) => {
      return state.domains.find((domain) => domain.id === parseInt(id + ''))
    },
  },
  actions: {
    setTitle(title: string) {
      document.title = title + ' | Imago Dashboard'
    },
    setShowMobileSidebar(value: boolean) {
      this.showMobileSidebar = value
    },
    setDomains(value: DomainInit[]) {
      this.domains = value
    },
    setHttpError(value: number | null) {
      this.httpError = value
    },
    updateDomain(newValue: DomainInit) {
      const domains = this.domains
      if (domains) {
        const index = domains.findIndex((d) => d.id === newValue.id)
        domains[index] = { ...domains[index], ...newValue }
        this.setDomains(domains)
      }
    },
  },
})
