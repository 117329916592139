export default {
  path: '/appointments',
  component: () => import('../layouts/Dashboard/Index.vue'),
  children: [
    {
      path: '',
      name: 'appointment',
      component: () => import('../views/Appointment/PageList.vue'),
    },
    {
      path: '/appointment/:id',
      props: true,
      name: 'appointment show',
      component: () => import('../views/Appointment/Show/Index.vue'),
    },
  ],
}
