// eslint-disable-next-line
// @ts-ignore
import { addMinutes, format, formatDistanceToNowStrict } from 'date-fns'

const replaceTimeCustom = (date: Date, str: string) => {
  // 0-11 for AM, 1-12 for PM
  if (format(date, 'a') === 'PM') {
    return str
  }

  return str.replace(format(date, 'hh:mm'), format(date, 'HH:mm'))
}

const formatDate = (dt: string | Date, fmt: string) => {
  if (!dt) return '-'

  const date = new Date(dt)

  return replaceTimeCustom(date, format(date, fmt))
}
const formatDateSchedule = (dt: string | Date) => {
  if (!dt) return '-'

  const date = new Date(dt)

  return (
    formatDate(date, 'EEE, dd MMM yyyy, hh:mm') +
    ' - ' +
    formatDate(addMinutes(date, 20), 'hh:mm aaa')
  )
}

const dateDiffHuman = (dt: string | Date) => {
  const date = new Date(dt)

  return formatDistanceToNowStrict(date, { addSuffix: true })
}

export default {
  dateDiffHuman: dateDiffHuman,
  date: formatDate,
  dateSchedule: formatDateSchedule,
}
