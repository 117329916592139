export default {
  path: '/users',
  component: () => import('../layouts/Dashboard/Index.vue'),
  children: [
    {
      path: '',
      name: 'user',
      component: () => import('../views/User/Index.vue'),
    },
    // {
    //   path: '/user/:id',
    //   props: true,
    //   name: 'user show',
    //   component: () => import('../views/User/Show.vue'),
    // },
  ],
}
