<script setup lang="ts">
  import { ref, watch } from 'vue'

  const props = withDefaults(
    defineProps<{
      src?: string | null
      type?: 'person' | 'question' | 'default'
    }>(),
    {
      src: '',
      type: 'default',
    },
  )
  const error = ref(false)

  watch(
    () => props.src,
    () => {
      error.value = false
    },
  )
</script>

<template>
  <div>
    <div
      v-if="!src || error"
      class="flex h-full w-full items-center justify-center bg-gray-200 text-gray-500"
    >
      <fa-icon
        v-if="type === 'person'"
        icon="i-far-user-tie"
        class="h-3/5 w-3/5"
      ></fa-icon>
      <fa-icon
        v-else-if="type === 'question'"
        icon="i-far-question"
        class="h-1/2 w-1/2"
      ></fa-icon>
      <fa-icon v-else icon="i-far-image" class="h-1/2 w-1/2"></fa-icon>
    </div>
    <img v-else class="h-full w-full" :src="src" @error="error = true" />
  </div>
</template>
