<script setup lang="ts">
  // https://vite-plugin-pwa.netlify.app/frameworks/vue.html#periodic-sw-updates
  import { useRegisterSW } from 'virtual:pwa-register/vue'
  import { ref } from 'vue'

  const { needRefresh, updateServiceWorker } = useRegisterSW()
  const updating = ref(false)
  const hasError = ref(false)
  const btnUpdateClicked = () => {
    try {
      updateServiceWorker()
      updating.value = true
    } catch (error) {
      hasError.value = true
    } finally {
      updating.value = false
    }
  }
</script>

<template>
  <div
    v-if="needRefresh"
    class="fixed bottom-0 right-0 z-10 m-4 border bg-white p-4 shadow-md"
    role="alert"
  >
    <div class="mb-2">Update available</div>
    <div v-if="hasError" class="mb-2 w-32 text-xs text-red-400">
      Failed to update. Please refresh and try again.
    </div>
    <div v-else class="text-center">
      <button
        class="mx-auto border px-4 py-1 text-sm outline-none"
        :disabled="updating"
        :class="{ 'cursor-not-allowed': updating }"
        @click="btnUpdateClicked"
      >
        {{ updating ? 'Updating' : 'Update' }}
      </button>
    </div>
  </div>
</template>
