<script setup lang="ts">
  import { computed } from 'vue'

  const props = defineProps({
    message: {
      type: [Array, String],
      default: () => [],
    },
  })

  const text = computed(() => {
    if (!props.message || props.message.length === 0) {
      return null
    }
    if (Array.isArray(props.message) && props.message.length) {
      return props.message[0]
    }
    return props.message
  })
</script>

<template>
  <div v-if="text" class="my-1 mb-2 text-xs text-red-500">
    {{ text }}
  </div>
</template>
