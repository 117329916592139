export default {
  path: '/program',
  component: () => import('../layouts/Dashboard/Index.vue'),
  children: [
    {
      path: '',
      name: 'program',
      component: () => import('../views/Program/PageList.vue'),
    },
  ],
}
