export default {
  path: '/reviews/summary',
  component: () => import('../views/Review/Index.vue'),
  children: [
    {
      path: '/reviews/list',
      component: () => import('../views/Review/Response/Index.vue'),
      children: [
        {
          path: '',
          name: 'review list',
          component: () => import('../views/Review/Response/List.vue'),
        },
        {
          path: '/review/:id',
          props: true,
          name: 'review show',
          component: () => import('../views/Review/Response/Show/Index.vue'),
        },
      ],
    },
    {
      path: '',
      name: 'review summary',
      component: () => import('../views/Review/Summary/Index.vue'),
    },
    {
      path: '/reviews/form',
      name: 'review form',
      component: () => import('../views/Review/Form/Index.vue'),
    },
  ],
}
