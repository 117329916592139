export default {
  path: '/domains',
  component: () => import('@/layouts/Dashboard/Index.vue'),
  children: [
    {
      path: '',
      name: 'domain',
      component: () => import('@/views/Domain/PageList.vue'),
    },
    {
      path: ':id',
      props: true,
      name: 'domain show',
      component: () => import('@/views/Domain/PageDetail.vue'),
    },
  ],
}
