<script setup>
  import { inject, ref, watch } from 'vue'

  const route = inject('route')
  const router = inject('router')

  const text = ref(route.query.search)

  let debounceTimeout
  watch(text, () => {
    clearTimeout(debounceTimeout)
    debounceTimeout = setTimeout(submitSearch, 500)
  })

  const submitSearch = () => {
    router.push({
      ...route, // name and params
      query: {
        // maintain other query params, but reset pagination
        ...route.query,
        page: undefined,
        // append or remove search to query params
        search: text.value ? text.value : undefined,
      },
    })
  }
</script>

<template>
  <form class="relative shadow" @submit.prevent="submitSearch">
    <input
      v-model="text"
      class="block h-12 w-full bg-white py-2 pl-12 font-medium text-gray-600 lg:pl-14"
      placeholder="Search..."
    />
    <div class="absolute inset-y-0 flex items-center px-4">
      <fa-icon
        icon="i-far-magnifying-glass"
        class="text-gray-600 lg:text-xl"
      ></fa-icon>
    </div>
  </form>
</template>
