import * as Sentry from '@sentry/vue'
import type { App } from 'vue'
import type { Router } from 'vue-router'

export default {
  install(app: App<Element>, router: Router) {
    // don't run in development
    if (import.meta.env.DEV) {
      return
    }

    Sentry.init({
      app,
      dsn: 'https://06bfa9d0bec54726a3ab57cf298b23ad@o4505000192901120.ingest.sentry.io/4505000314601472',
      integrations: [
        new Sentry.BrowserTracing({
          routingInstrumentation: Sentry.vueRouterInstrumentation(router),
          tracePropagationTargets: ['api.imagowellness.co.nz', /^\//],
        }),
      ],
      release: __APP_VERSION__,
      ignoreErrors: [],

      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 0.001,
    })
  },
}
