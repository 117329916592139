import clickOutside from './clickOutside'

/**
 * Trigger focus event when component is loaded.
 */
const focus = {
  mounted(el) {
    if (el instanceof HTMLDivElement) {
      const component = el.querySelector('input, textarea')
      component?.focus()
    } else {
      el.focus()
    }
  },
}

export default {
  install(Vue) {
    Vue.directive('click-outside', clickOutside)
    Vue.directive('focus', focus)
  },
}
