import { defineStore } from 'pinia'
import api from '@/functions/api'

interface State {
  user: UserInit | null
  permissions: string[]
  firebaseToken: string | null
}

export const useAuthStore = defineStore('Auth', {
  state: (): State => ({
    user: null,
    permissions: [],
    firebaseToken: null,
  }),
  getters: {
    hasPermission: (state) => (permission: string) => {
      return state.permissions.includes(permission)
    },
  },
  actions: {
    setUser(value: UserInit | null) {
      this.user = value
    },
    setPermissions(value: string[]) {
      this.permissions = value
    },
    setFirebaseToken(value: string) {
      this.firebaseToken = value
    },
    setCountUnreadNotification(value: number) {
      if (this.user) {
        this.user.count_unread_notifications = value
      }
    },
    decrementCountUnreadNotification() {
      if (this.user) {
        this.user.count_unread_notifications--
      }
    },
    logout() {
      api.POST('logout', { firebase_token: this.firebaseToken ?? '' })
      this.router.replace({ name: 'login' })
      this.setUser(null)
    },
  },
})
