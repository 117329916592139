<script setup lang="ts">
  import { computed, nextTick } from 'vue'
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  import VueCleave from 'vue-cleave-component/src'

  const props = defineProps<{
    modelValue: string | number
    min?: number
    max?: number
    // decimalSeparator?: string,
    precision?: number
    selectOnInput?: boolean
    disabled?: boolean
    required?: boolean
  }>()

  const emit = defineEmits<{
    (e: 'update:modelValue', value: number): void
  }>()

  const options = computed(() => ({
    numeral: true,
    numeralDecimalScale: props.precision,
    numeralPositiveOnly: true,
    // numeralDecimalMark: props.decimalSeparator,
    // delimiter: props.thousandSeparator,
  }))

  const computedValue = computed({
    get() {
      return props.modelValue
    },
    set(newValue) {
      const value = Number(newValue)
      emit('update:modelValue', value)
      nextTick(() => {
        const min = props.min ?? 0
        const max = props.max ?? Number.MAX_SAFE_INTEGER
        if (value < min) {
          emit('update:modelValue', min)
        } else if (value > max) {
          emit('update:modelValue', max)
        }
      })
    },
  })

  const onFocus = (event: FocusEvent) => {
    if (props.selectOnInput && event.target instanceof HTMLInputElement) {
      event.target.select()
    }
  }
</script>

<template>
  <vue-cleave
    v-model.number="computedValue"
    :options="options"
    :disabled="disabled"
    :required="required"
    @focus="onFocus($event)"
  ></vue-cleave>
</template>
