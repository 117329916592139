import {
  BarController,
  BarElement,
  CategoryScale,
  Chart,
  Legend,
  LineController,
  LineElement,
  LinearScale,
  PointElement,
  TimeScale,
  Tooltip,
} from 'chart.js'
import 'chartjs-adapter-date-fns'

Chart.register(
  BarController,
  LineController,
  LinearScale,
  CategoryScale,
  TimeScale,
  BarElement,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
)
