export default {
  path: '/companies',
  component: () => import('../layouts/Dashboard/Index.vue'),
  children: [
    {
      path: '',
      name: 'company',
      component: () => import('../views/Company/Index.vue'),
    },
    {
      path: '/company/:companyId',
      props: true,
      component: () => import('../views/Company/Show/Index.vue'),
      children: [
        {
          path: 'summary',
          name: 'company show',
          component: () => import('../views/Company/Show/Summary/Index.vue'),
        },
        {
          path: 'members',
          name: 'company show members',
          component: () => import('../views/Company/Show/Members.vue'),
        },
      ],
    },
  ],
}
