export default {
  path: '/program-purchases',
  component: () => import('@/layouts/Dashboard/Index.vue'),
  children: [
    {
      path: '',
      name: 'program purchases',
      component: () => import('@/views/ProgramOrder/PageList.vue'),
    },
  ],
}
