export default {
  path: '/coaches',
  component: () => import('../layouts/Dashboard/Index.vue'),
  children: [
    {
      path: '',
      name: 'coach',
      component: () => import('../views/Coach/Index.vue'),
    },
    {
      path: '/coach/:id',
      props: true,
      name: 'coach show',
      component: () => import('../views/Coach/Show/Index.vue'),
    },
  ],
}
