<script setup lang="ts">
  import {
    Dialog as AppDialog,
    DialogPanel,
    TransitionChild,
    TransitionRoot,
  } from '@headlessui/vue'
  import { ref } from 'vue'

  defineProps<{
    maxWidth?: string
  }>()

  const isOpen = ref(false)

  const open = () => {
    isOpen.value = true
  }
  const close = () => {
    isOpen.value = false
  }

  defineExpose({ open, close })
</script>

<template>
  <teleport to="body">
    <transition-root appear :show="isOpen" as="template">
      <app-dialog as="div" class="relative z-40" @close="close">
        <transition-child
          as="template"
          enter="duration-300 ease-out"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="duration-200 ease-in"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div class="fixed inset-0 bg-black bg-opacity-25"></div>
        </transition-child>

        <div class="fixed inset-0 overflow-y-auto">
          <div
            class="flex min-h-full items-center justify-center p-4 text-center"
          >
            <transition-child
              as="template"
              enter="duration-300 ease-out"
              enter-from="opacity-0 scale-95"
              enter-to="opacity-100 scale-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100 scale-100"
              leave-to="opacity-0 scale-95"
            >
              <dialog-panel
                class="w-full transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all"
                :style="{ 'max-width': maxWidth ?? '28rem' }"
              >
                <div class="absolute right-4 top-5">
                  <button
                    class="flex h-8 w-8 items-center justify-center rounded-full text-gray-500 hover:bg-gray-200"
                    @click="close"
                  >
                    <fa-icon icon="i-far-xmark"></fa-icon>
                  </button>
                </div>

                <div>
                  <slot></slot>
                </div>
              </dialog-panel>
            </transition-child>
          </div>
        </div>
      </app-dialog>
    </transition-root>
  </teleport>
</template>

<style lang="postcss" scoped>
  :deep() {
    .modal-title {
      @apply mb-6 text-lg font-medium;
    }
    .modal-footer {
      @apply mt-6 flex items-center justify-center space-x-4;
    }
  }
</style>
