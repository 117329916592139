export default {
  path: '/user/groups',
  component: () => import('../layouts/Dashboard/Index.vue'),
  children: [
    {
      path: '',
      name: 'user groups',
      component: () => import('../views/UserGroups/Index.vue'),
    },
    {
      path: '/user/group/:groupId',
      component: () => import('../views/UserGroups/Show/Index.vue'),
      children: [
        {
          path: 'summary',
          name: 'user group show summary',
          component: () => import('../views/UserGroups/Show/Summary/Index.vue'),
        },
        {
          path: 'members',
          name: 'user group show members',
          component: () => import('../views/UserGroups/Show/Members.vue'),
        },
      ],
    },
  ],
}
