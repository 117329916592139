<template>
  <div class="relative">
    <g-input :type="type" v-bind="$attrs"></g-input>
    <button
      class="absolute right-0 top-6 p-2 text-gray-500"
      tabindex="100"
      type="button"
      @click="showPassword = !showPassword"
    >
      <fa-icon v-if="showPassword" icon="i-far-eye-slash"></fa-icon>
      <fa-icon v-else icon="i-far-eye"></fa-icon>
    </button>
  </div>
</template>

<script>
  export default {
    name: 'InputPassword',
    inheritAttrs: false,
    data() {
      return {
        showPassword: false,
      }
    },
    computed: {
      type() {
        return this.showPassword ? 'text' : 'password'
      },
    },
  }
</script>

<style lang="postcss" scoped></style>
