import type { App } from 'vue'
import AppButton from './AppButton.vue'
import AppEmpty from './AppEmpty.vue'
import AppErrorMsg from './AppErrorMessage.vue'
import AppImageWrapper from './AppImageWrapper.vue'
import AppInput from './AppInput.vue'
import AppModal from './AppModal.vue'
import AppPagination from './AppPagination.vue'
import AppTabStatus from './AppTabStatus.vue'
import AppTabStatusItem from './AppTabStatusItem.vue'
import AppTable from './AppTable.vue'
import FaIcon from './FaIcon.vue'
import InputPassword from './InputPassword.vue'
import Navbar from './NavbarTitle.vue'
import Search from './Search.vue'

export default {
  install(Vue: App) {
    Vue.component('AppTabStatus', AppTabStatus)
    Vue.component('AppTabStatusItem', AppTabStatusItem)
    Vue.component('AppButton', AppButton)
    Vue.component('GModal', AppModal)
    Vue.component('AppEmpty', AppEmpty)
    Vue.component('AppErrorMsg', AppErrorMsg)
    Vue.component('GInput', AppInput)
    Vue.component('GPassword', InputPassword)
    Vue.component('AppPagination', AppPagination)
    Vue.component('AppTable', AppTable)
    Vue.component('GSearch', Search)
    Vue.component('AppImg', AppImageWrapper)
    Vue.component('GNavbar', Navbar)
    Vue.component('FaIcon', FaIcon)
  },
}

declare module '@vue/runtime-core' {
  export interface GlobalComponents {
    AppTabStatus: typeof AppTabStatus
    AppTabStatusItem: typeof AppTabStatusItem
    AppButton: typeof AppButton
    GModal: typeof AppModal
    AppEmpty: typeof AppEmpty
    AppErrorMsg: typeof AppErrorMsg
    GInput: typeof AppInput
    GPassword: typeof InputPassword
    AppPagination: typeof AppPagination
    AppTable: typeof AppTable
    GSearch: typeof Search
    AppImg: typeof AppImageWrapper
    GNavbar: typeof Navbar
    FaIcon: typeof FaIcon
  }
}
