<script setup lang="ts">
  import { computed, useAttrs } from 'vue'
  import fmt from '@/functions/formatters'
  import InputNumeric from './InputNumeric.vue'

  // type select, will have option with empty value and empty label, set prop nullable = true to disable this

  const props = defineProps<{
    modelValue: string | number
    id?: string
    classLabel?: string | object
    error?: string | string[]
    type?: string
    maxlength?: string | number
    hideMaxLength?: boolean
    options?: { value: string | number; label: string }[]
    placeholder?: string
    rows?: string | number
    autocomplete?: string
    precision?: number
    disabled?: boolean
    required?: boolean
  }>()

  defineOptions({ inheritAttrs: false })
  const attrs = useAttrs()

  const emit = defineEmits<{
    (e: 'update:modelValue', data: string | number): void
  }>()

  const hasError = computed(() => props.error?.length)
</script>

<template>
  <div class="card my-2 py-1 shadow-md">
    <label :for="id" class="text-xs" :class="classLabel">
      {{ placeholder }}
    </label>
    <div class="flex items-end space-x-2" :class="{ 'has-error': hasError }">
      <textarea
        v-if="type === 'textarea'"
        :id="id"
        class="form-input placeholder-transparent"
        :class="attrs.class"
        :rows="rows"
        :value="modelValue"
        :maxlength="maxlength"
        :autocomplete="autocomplete"
        :placeholder="placeholder"
        :disabled="disabled"
        :required="required"
        @input="
          emit('update:modelValue', ($event.target as HTMLInputElement).value)
        "
      ></textarea>
      <input-numeric
        v-else-if="type === 'number'"
        :id="id"
        :model-value="modelValue"
        class="form-input placeholder-transparent"
        :class="attrs.class"
        :precision="precision"
        :placeholder="placeholder"
        :disabled="disabled"
        @update:model-value="emit('update:modelValue', $event)"
      ></input-numeric>
      <div v-else-if="type === 'select'" class="-mx-1 w-full">
        <select
          :id="id"
          :value="modelValue"
          class="form-input placeholder-transparent"
          :class="attrs.class"
          :disabled="disabled"
          :placeholder="placeholder"
          :required="required"
          @change="
            emit('update:modelValue', ($event.target as HTMLInputElement).value)
          "
        >
          <option v-if="attrs.nullable" :value="attrs.emptyValue ?? 0">
            {{ attrs.emptyLabel ?? '- Select -' }}
          </option>
          <option
            v-for="{ value, label } in options"
            :key="value"
            :value="value"
          >
            {{ label }}
          </option>
        </select>
      </div>
      <input
        v-else
        :id="id"
        class="form-input placeholder-transparent"
        :class="attrs.class"
        :type="type"
        :value="modelValue"
        :maxlength="maxlength"
        :autocomplete="autocomplete"
        :placeholder="placeholder"
        :disabled="disabled"
        :required="required"
        @input="
          emit('update:modelValue', ($event.target as HTMLInputElement).value)
        "
      />
      <div
        v-if="
          maxlength &&
          type !== 'number' &&
          typeof modelValue === 'string' &&
          !hideMaxLength
        "
        class="relative"
      >
        <span class="text-xxs">
          {{ fmt.number(Number(maxlength) - modelValue.length) }}
        </span>
      </div>
    </div>
    <app-error-msg :message="error"></app-error-msg>
  </div>
</template>

<style lang="postcss">
  .form-input {
    @apply w-full py-1 text-sm font-medium focus:outline-none disabled:cursor-not-allowed;
  }
  .has-error .form-input {
    @apply border-b-red-400;
  }
</style>
