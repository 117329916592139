export default {
  path: '/redeem-code',
  component: () => import('../layouts/Dashboard/Index.vue'),
  children: [
    {
      path: '',
      name: 'redeem code',
      component: () => import('../views/RedeemCode/PageList.vue'),
    },
  ],
}
