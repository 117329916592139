import { createRouter, createWebHistory } from 'vue-router'
import appointment from './appointment'
import auth from './auth'
import coach from './coach'
import company from './company'
import domain from './domain'
import program from './program'
import programOrder from './program-order'
import redeemCode from './redeem-code'
import review from './review'
import user from './user'
import userGroup from './userGroup'

const routes = [
  {
    path: '/',
    component: () => import('../layouts/Dashboard/Index.vue'),
    children: [
      {
        path: '/',
        name: 'dashboard',
        component: () => import('../views/Dashboard/Index.vue'),
      },
      {
        path: '/notifications',
        name: 'notification',
        component: () => import('../views/Notification/Index.vue'),
      },
      {
        path: '/topup-pricings',
        name: 'topup pricings',
        component: () => import('../views/Topup/Pricing/PageList.vue'),
      },
      {
        path: '/admins',
        name: 'admin',
        component: () => import('../views/Admin/Index.vue'),
      },
      {
        path: '/changelogs',
        name: 'changelog',
        component: () => import('../views/Changelog/PageIndex.vue'),
      },
      {
        path: '/settings',
        name: 'setting',
        component: () => import('../views/Setting/Index.vue'),
      },
      {
        path: 'corporate-quotes',
        name: 'corporate quotes',
        component: () => import('../views/CorporateQuote/Index.vue'),
      },
      {
        path: 'topups',
        name: 'topup',
        component: () => import('../views/Topup/PageList.vue'),
      },
      review,
    ],
  },
  appointment,
  coach,
  company,
  program,
  programOrder,
  domain,
  userGroup,
  user,
  redeemCode,
  auth,
  {
    path: '/maintenance',
    name: 'maintenance',
    component: () => import('../views/Error/503.vue'),
  },
  {
    path: '/',
    component: () => import('../layouts/Dashboard/Index.vue'),
    children: [
      {
        path: '/:pathMatch(.*)*',
        name: '404',
        component: () => import('../views/Error/404.vue'),
      },
    ],
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

export default router
