import axios from 'axios'

axios.defaults.withCredentials = true

const config = {
  baseURL: import.meta.env.VITE_URL_API,
  timeout: 30000, // 30s
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
    Timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  },
  withCredentials: true,
  withXSRFToken: true,
}

const REQUEST = (conf, retryIfCsrfError = true) => {
  return new Promise(async (resolve, reject) => {
    const requestConfig = { ...config, ...conf }
    try {
      const response = await axios.request(requestConfig)
      resolve(response)
    } catch (error) {
      if (isCsrfTokenError(error) && retryIfCsrfError) {
        await GET('/sanctum/csrf-cookie')
        resolve(REQUEST(conf, false))
      } else {
        handleErrors(error)
        reject(error)
      }
    }
  })
}
const GET = (url, params = {}) => REQUEST({ method: 'get', url, params })
const POST = (url, data = {}) => REQUEST({ method: 'post', url, data })
const PUT = (url, data = {}) => REQUEST({ method: 'put', url, data })
const DELETE = (url, data = {}) => REQUEST({ method: 'delete', url, data })
const POSTFORMDATA = (url, data) => {
  const formData = new FormData()
  Object.keys(data).forEach((key) => {
    formData.append(key, data[key])
  })

  return REQUEST({
    headers: { ...config.headers, 'Content-Type': 'multipart/form-data' },
    method: 'post',
    url,
    data: formData,
  })
}

const onError = {
  unauthorized: () => {
    //
  },
  maintenance: () => {
    //
  },
  forbidden: () => {
    //
  },
  notFound: () => {
    //
  },
  unknown: () => {
    //
  },
}

/* https://github.com/axios/axios#handling-errors */
const handleErrors = (error) => {
  console.error(error)
  switch (error.response?.status) {
    case 401:
      return onError.unauthorized()
    case 403:
      return onError.forbidden()
    case 404:
      return onError.notFound()
    case 503:
      return onError.maintenance()
    default:
      return onError.unknown()
  }
}

const isCsrfTokenError = (error) => {
  return (
    error.response?.status === 419 &&
    error.response.data.message === 'CSRF token mismatch.'
  )
}

export default {
  GET,
  POST,
  DELETE,
  PUT,
  POSTFORMDATA,
  onError,
  config,
}
