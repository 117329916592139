<script setup lang="ts"></script>

<template>
  <div class="overflow-auto">
    <table class="table">
      <slot></slot>
    </table>
  </div>
</template>

<style lang="postcss" scoped>
  :deep() {
    .table {
      @apply w-full border-l;
      border-left: 1rem solid white;
      border-right: 1rem solid white;
      thead th,
      thead td {
        @apply whitespace-nowrap border-gray-500 bg-gray-500 py-2 font-medium text-white;
      }
      th,
      td {
        @apply px-4;
      }
      td {
        @apply border-0 border-b border-gray-200 py-2 text-sm sm:py-4 sm:text-base;
      }
      th:first-child {
        border-left-width: 1rem;
      }
      th:last-child {
        border-right-width: 1rem;
      }
      th:first-child,
      td:first-child {
        padding-left: 0;
      }
      th:last-child,
      td:last-child {
        padding-right: 0;
      }
      tr:last-child td {
        @apply border-b-0;
      }
    }
  }
</style>
