// initialize chart.js
import { createPinia } from 'pinia'
// styles
import 'virtual:uno.css'
import { createApp, markRaw } from 'vue'
import type { Router } from 'vue-router'
import App from './App.vue'
import './assets/tailwind.css'
// global stuffs
import globalComponents from './components'
import './components/Charts'
import globalDirectives from './directives'
// helper functions
import Api from './functions/api.js'
import { setTitle } from './functions/app.js'
import formatter from './functions/formatters'
import router from './routes'
// 3rd party services
import sentry from './sentry'

const pinia = createPinia()
pinia.use(({ store }) => {
  store.router = markRaw(router)
})

const app = createApp(App)
  .use(pinia)
  .use(router)
  .use(sentry, router)
  .use(globalComponents)
  .use(globalDirectives)

app.config.globalProperties.fmt = formatter
app.config.globalProperties.api = Api
app.config.globalProperties.setTitle = setTitle
app.config.performance = true

app.mount('#app')

declare module 'pinia' {
  export interface PiniaCustomProperties {
    router: Router
  }
}
