<script setup lang="ts">
  import { provide, ref } from 'vue'
  import { useRoute, useRouter } from 'vue-router'
  import Toast from '@/components/AppToast.vue'
  import { useAuthStore } from '@/stores/auth'
  import { useGeneralStore } from '@/stores/general'
  import PwaUpdatePrompt from './PwaUpdatePrompt.vue'
  import api from './functions/api'
  import { setTitle } from './functions/app'
  import formatter from './functions/formatters'

  const auth = useAuthStore()
  const general = useGeneralStore()

  const route = useRoute()
  provide('route', route)

  const router = useRouter()
  provide('router', router)

  api.onError.maintenance = () => {
    router.replace({ name: 'maintenance' })
  }
  api.onError.unauthorized = () => {
    if (window.location.pathname !== '/login') {
      const path = encodeURI(location.pathname.substring(1) + location.search)
      const query = path ? { r: path } : {}
      auth.setUser(null)
      router.replace({ name: 'login', query })
    }
  }
  api.onError.notFound = () => {
    general.setHttpError(404)
  }
  api.onError.forbidden = () => {
    general.setHttpError(403)
  }

  provide('api', api)
  provide('fmt', formatter)
  provide('setTitle', setTitle)

  const toastComponent = ref<InstanceType<typeof Toast> | null>()
  provide('toast', (message: string) => toastComponent.value?.newToast(message))

  api.GET('/sanctum/csrf-cookie')
</script>

<template>
  <router-view></router-view>
  <pwa-update-prompt></pwa-update-prompt>
  <toast ref="toastComponent"></toast>
</template>
