<script setup lang="ts">
  import { computed } from 'vue'
  import { useRoute } from 'vue-router'
  import fmt from '@/functions/formatters'

  const props = defineProps<{ modelValue?: Pagination }>()

  const route = useRoute()
  const pages = computed(() => {
    const currentPage = props.modelValue?.current_page ?? 1
    const lastPage = props.modelValue?.last_page ?? 0
    const maxShownButton = 5
    const pages = Array.from({ length: lastPage }, (_, i) => i + 1)
    // ex: currentPage = 7, maxShownButton = 5, should return 5,6,7,8,9, current page at the center
    // ex: currentPage = 1, maxShownButton = 5, should return 1,2,3,4,5
    // ex: currentPage = 10, lastPage = 10, maxShownButton = 5, should return 6,7,8,9,10
    const start = Math.max(
      0,
      Math.min(
        currentPage - Math.floor(maxShownButton / 2 + 1),
        lastPage - maxShownButton,
      ),
    )
    const end = start + maxShownButton
    return pages.slice(start, end)
  })

  const getRoute = (p: number | string) => ({
    ...route,
    query: {
      ...route.query,
      page: p === 1 ? undefined : p,
    },
  })
</script>

<template>
  <nav
    v-if="modelValue && modelValue?.last_page > 1 && modelValue?.from"
    role="navigation"
    aria-label="Pagination Navigation"
    class="pagination"
  >
    <div class="text">
      Showing
      <span class="font-semibold">
        {{ fmt.number(modelValue?.from) }}-{{ fmt.number(modelValue?.to) }}
      </span>
      of
      <span class="font-semibold">{{ fmt.number(modelValue?.total) }}</span>
      data
    </div>
    <div class="buttons">
      <router-link
        v-for="page in pages"
        :key="page"
        :to="getRoute(page)"
        :class="{
          active: parseInt((modelValue?.current_page ?? 1) + '') === page,
        }"
      >
        {{ fmt.number(page) }}
      </router-link>
    </div>
  </nav>
</template>

<style lang="postcss" scoped>
  .pagination {
    @apply mt-4 flex items-center justify-end space-x-4;

    .text {
      @apply text-xs text-gray-500;
    }
    .buttons {
      @apply flex space-x-1;

      a {
        @apply border bg-white p-2 text-center text-xs text-gray-500 hover:bg-gray-100;
        min-width: 2rem;
      }
      .active {
        @apply border-primary-500 bg-primary-500 text-white hover:bg-primary-500;
      }
    }
  }
</style>
